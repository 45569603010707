export const siteInfo = {
  name: "SkyShipBD",
  navColor: "#0B60A9",
  secondary: "#007057",
  primary_light_bg: "#E9EFF0",
  asideColor: "#fff",
  payable: 0.6,
  minimum_price: 1000,
  url: "https://skyshipbd.com",
  cdnUrl: "https://cdn.skyshipbd.com",
  phone: "(+88) 09613828606",
  address: "House#42, Road-3/A, Dhanmondi, Dhaka-1209",
  minimum_quantity: 3,
  error: "#C4161C",
  email: "skyshipbd@gmail.com",
  tableHeader: "#E9F0EE",
  by_air: {
    a: 630,
    b: 750,
  },
};
