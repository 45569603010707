import {
  AuditOutlined,
  BarChartOutlined,
  CalendarOutlined,
  CarOutlined,
  CloudServerOutlined,
  DesktopOutlined,
  DollarOutlined,
  GiftOutlined,
  GoldOutlined,
  HomeOutlined,
  LogoutOutlined,
  PictureOutlined,
  SelectOutlined,
  SettingOutlined,
  TeamOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  BarcodeOutlined,
  BuildOutlined,
  SlidersOutlined,
  OneToOneOutlined,
  EuroCircleOutlined,
  AppstoreOutlined,
  ContainerOutlined,
  OrderedListOutlined,
  QuestionCircleOutlined,
  StopOutlined,
  CodeSandboxOutlined,
  AppstoreAddOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { Layout, Menu, notification } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { IoCashOutline } from "react-icons/io5";
import SubMenu from "antd/es/menu/SubMenu";
import { logoutAdmin } from "../actions/AuthAction";
import { isPermitted } from "../utils/PermissionManager";
//   import { sidebarItems } from "../utils/Sidebars";
const { Sider } = Layout;

export default function SideMenu(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const roles = useSelector((state) => state.auth.profile?.roles);
  const openNotificationWithIcon = (type, message, description) => {
    notification[type]({
      message: message,
      description: description,
    });
  };

  const menuStyle = {
    fontSize: "16px",
    width: "18px",
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      defaultSelectedKeys={[path.toString()]}
      defaultOpenKeys={["bookings"]}
    >
      <Menu.Item key={"/"} icon={<HomeOutlined style={menuStyle} />}>
        <Link to={"/"}>Dashboard</Link>
      </Menu.Item>
      {isPermitted(roles, ["admin", "customer_care"]) && (
        <Menu.Item key={"/customers"} icon={<TeamOutlined style={menuStyle} />}>
          <Link to={"/customers"}>Customers</Link>
        </Menu.Item>
      )}
      <Menu.Item
        key={"/bookings"}
        icon={<OrderedListOutlined style={menuStyle} />}
      >
        <Link to={"/bookings"}>Bookings</Link>
      </Menu.Item>
      <Menu.Item
        key={"/unknown-bookings"}
        icon={<QuestionCircleOutlined style={menuStyle} />}
      >
        <Link to={"/unknown-bookings"}>Unknown</Link>
      </Menu.Item>
      <Menu.Item key={"/unreceived"} icon={<StopOutlined style={menuStyle} />}>
        <Link to={"/unreceived"}>Unreceived</Link>
      </Menu.Item>
      {/* <SubMenu key="bookings" icon={<ContainerOutlined />} title="Bookings">

        <Menu.Item
          key={"/unreceived"}
          icon={<StopOutlined style={menuStyle} />}
        >
          <Link to={"/unreceived"}>Unreceived</Link>
        </Menu.Item>
        <Menu.Item
          key={"/unknown"}
          icon={<QuestionCircleOutlined style={menuStyle} />}
        >
          <Link to={"/unknown"}>Unknown</Link>
        </Menu.Item>
      </SubMenu> */}

      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/cartons"}
          icon={<CloudServerOutlined style={menuStyle} />}
        >
          <Link to={"/cartons"}>Cartons</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/multi-cartons"}
          icon={<AppstoreOutlined style={menuStyle} />}
        >
          <Link to={"/multi-cartons"}>Multi Cartons</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/shipments"}
          icon={<BarcodeOutlined style={menuStyle} />}
        >
          <Link to={"/shipments"}>Shipments</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/sheets"}
          icon={<DatabaseOutlined style={menuStyle} />}
        >
          <Link to={"/sheets"}>Sheets</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/categories"}
          icon={<AppstoreAddOutlined style={menuStyle} />}
        >
          <Link to={"/categories"}>Categories</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/carton-allocation"}
          icon={<CodeSandboxOutlined style={menuStyle} />}
        >
          <Link to={"/carton-allocation"}>Carton Allocation</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "shipment"]) && (
        <Menu.Item
          key={"/faulty-allocation"}
          icon={<StopOutlined style={menuStyle} />}
        >
          <Link to={"/faulty-allocation"}>Faulty Allocation</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "shipment", "delivery"]) && (
        <Menu.Item
          key={"/quality-control"}
          icon={<SelectOutlined style={menuStyle} />}
        >
          <Link to={"/quality-control"}>QC</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item key={"/delivery"} icon={<CarOutlined style={menuStyle} />}>
          <Link to={"/delivery"}>Delivery</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/courier"}
          icon={<SlidersOutlined style={menuStyle} />}
        >
          <Link to={"/courier"}>Courier</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/office-delivery"}
          icon={<OneToOneOutlined style={menuStyle} />}
        >
          <Link to={"/office-delivery"}>Office Delivery</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "accountant"]) && (
        <Menu.Item key={"/payments"} icon={<IoCashOutline style={menuStyle} />}>
          <Link to={"/payments"}>Payments</Link>
        </Menu.Item>
      )}

      {/* {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/invoice"}
          icon={<OneToOneOutlined style={menuStyle} />}
        >
          <Link to={"/invoice"}>Invoice</Link>
        </Menu.Item>
      )} */}

      {/* {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/courier"}
          icon={<SlidersOutlined style={menuStyle} />}
        >
          <Link to={"/courier"}>Courier</Link>
        </Menu.Item>
      )}
      {isPermitted(roles, ["admin", "delivery"]) && (
        <Menu.Item
          key={"/office-delivery"}
          icon={<OneToOneOutlined style={menuStyle} />}
        >
          <Link to={"/office-delivery"}>Office Delivery</Link>
        </Menu.Item>
      )}

      {isPermitted(roles, ["admin", "accountant"]) && (
        <Menu.Item key={"/payments"} icon={<IoCashOutline style={menuStyle} />}>
          <Link to={"/payments"}>Payments</Link>
        </Menu.Item>
      )} */}

      {/* {isPermitted(roles, ["admin"]) && (
        <SubMenu key="reports" icon={<BarChartOutlined />} title="Reports">
          <Menu.Item
            key={"/monthly-report"}
            icon={<CalendarOutlined style={menuStyle} />}
          >
            <Link to={"/monthly-report"}>Monthly Report</Link>
          </Menu.Item>
        </SubMenu>
      )} */}

      {/* {isPermitted(roles, ["admin"]) && (
        <SubMenu key="settings" icon={<SettingOutlined />} title="Settings">
          <Menu.Item
            key={"/settings/rates"}
            icon={<EuroCircleOutlined style={menuStyle} />}
          >
            <Link to={"/settings/rates"}>Rates & Margin</Link>
          </Menu.Item>
        </SubMenu>
      )} */}

      {isPermitted(roles, ["superadmin"]) && (
        <Menu.Item
          key="/admins"
          icon={
            <UsergroupAddOutlined style={{ fontSize: "14px", width: "18px" }} />
          }
        >
          <Link to={"/admins"}>Admins</Link>
        </Menu.Item>
      )}

      <Menu.Item
        key="/SettingOutlined "
        icon={<UserOutlined style={{ fontSize: "14px", width: "18px" }} />}
      >
        <Link to={"/profile"}>Profile</Link>
      </Menu.Item>
      <Menu.Item
        key="/logout"
        onClick={() => {
          dispatch(logoutAdmin(() => {}));
          openNotificationWithIcon(
            "success",
            "Success",
            "Logged out successfully"
          );
        }}
        icon={<LogoutOutlined style={{ fontSize: "16px", width: "18px" }} />}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
}
